import React from "react";
import './About.css'

const About = () => {
    return(
        <React.Fragment>
                <div className="column">
                <div className="container">
                <h1 className="title">About our company:</h1>
                <p className="p"> Our urgency to start generating qualified and consistent roofing leads came across the variety on the market, many companies will be able to set appointments but barely be able to engage clients. We fused the art of converting a call into an appointment and pre-sell for you, while representing your company at all times. 
The team works to give your company the best storm leads on the market. Don't lose potential customers, we bring you closer to them.
                    </p>
                    <p>
                    All of our leads are made with the homeowner/decision maker of the property. A qualified lead contains; roof age, roof type, story levels, insurance coverage and we always make sure the homeowner is not under any roofing contract.</p>
            </div>
                </div>
        </React.Fragment>
    )
}

export default About;