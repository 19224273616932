import React from 'react'

import Pricing from '../misc/Pricing'
import Header from '../misc/Header'
import About from '../misc/About'
import ContactButton from '../misc/ContactButton'

const Home = () => {
    return (
        <React.Fragment>
            <Header />
            <About />
            <Pricing />
            <ContactButton />
        </React.Fragment>
    )
}
export default Home;
