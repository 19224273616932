import React from 'react'
import './Pricing.css'

const Home = () => {
    return (
        <React.Fragment>
            <section className="content-container">
            <div className="columns">
                <ul className="price">
                    <li className="col-header">BASIC</li>
                    <li className="grey">$85.00</li>
                    <li>GOOGLE MAPS IMAGERY</li>
                    <li>HAIL/WIND STORM IMAGERY </li>
                    <li>DATE OF LOSS.</li>
                    <li></li>
                </ul>
                </div>

                <div className="columns">
                <ul className="price">
                    <li className="col-header" style={{backgroundColor:'#221F55'}}>RECORDING</li>
                    <li className="grey">$ 95.00</li>
                    <li>RECORDING OF THE CALL</li>
                    <li>GOOGLE MAPS IMAGERY </li>
                    <li>HAIL/WIND STORM IMAGERY</li>
                    <li>DATE OF LOSS</li>
                    <li></li>
                </ul>
                </div>

                <div className="columns">
                <ul className="price">
                    <li className="col-header">IROOFING REPORT</li>
                    <li className="grey">$ 100.00</li>
                    <li>RECORDING OF THE CALL</li>
                    <li>IROOFING REPORT</li>
                    <li>GOOGLE MAPS IMAGERY</li>
                    <li>HAIL/WIND STORM IMAGERY</li>
                    <li>DATE OF LOSS.</li>
                </ul>
            </div>
            </section>
        </React.Fragment>
    )
}

export default Home;