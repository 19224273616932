import React from 'react'
import './ContactButton.css'

const ContactButton = () =>{
    return (
        <React.Fragment>
            <div className="fab-wrapper">
  <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
  <label className="fab" for="fabCheckbox">
    <span className="fab-dots fab-dots-1"></span>
    <span className="fab-dots fab-dots-2"></span>
    <span className="fab-dots fab-dots-3"></span>
  </label>
  <div className="fab-wheel">
  <a href="mailto:stormleadsinnovation@gmail.com?&subject=Mail from stormleadsinnovation.com&body=Hello I need information about your packages" target="_blank" className="fab-action fab-action-1">
      <i className="fa fa-envelope fa-"></i>
    </a>
    <a href="https://www.facebook.com/SLIstormleadsinnovation" target="_blank" className="fab-action fab-action-2">
    <i className="fa fa-facebook"></i>
    </a>
    <a href="https://wa.me/13122635999?text=Hello I need information about your packages" target="_blank" className="fab-action fab-action-3">
      <i className="fa fa-whatsapp"></i>
    </a>
  </div>
</div>
        </React.Fragment>
    )
}

export default ContactButton;