import React from 'react'
import './Footer.css'

const MenuBar = () => {
    return (
        <footer >
            <div className="footer">
            <div className="column">
                <h1 className="title">Contact:</h1>
                <p className="p">(312) 263-5999</p>
                    <p>stormleadsinnovation@gmail.com</p>
                </div>
            </div>
        </footer>
    )
}

export default MenuBar;