import React from 'react'

const Contact = () => {
    return (
        <React.Fragment>
            <section className="content-container">
                <div className="textArea"> 
                    <h2>Call us to generate a package just for you and your needs: </h2>
                </div>

                <div className="block">
                    <div className="row">
                        <div className="col">
                            <div id="details">
                                <table>
                                    <tbody>
                                        <tr>
                                        <i class="fa-brands fa-whatsapp"></i>
                                            <td><a href="https://wa.me/13122635999?text=Hello I need information about your packages" target="_blank">(312) 263-5999</a></td>
                                        </tr>
                                        <tr>
                                            <td><i className="fa fa-envelope fa- " ></i></td>
                                            <td><a href="mailto:stormleadsinnovation@gmail.com?&subject=Mail from stormleadsinnovation.com&body=Hello I need information about your packages" target="_blank">stormleadsinnovation@gmail.com</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Contact;