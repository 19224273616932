import React from 'react'
import { Link } from "react-router-dom"
import './Header.css'

const Header = () => {
    return (
        <React.Fragment>
            <header className="bg-image">
            {/*<div className="bg-container">
                    <h1>Storm Leads Innovation</h1>
                    <h2>Placeholder for home page header</h2>
                </div>*/}
            </header>
        </React.Fragment>
    )
}

export default Header;